// Initial State
const initialState = {
  cursorPos: { x: 0, y: 0 },
  cursorStyle: false
}

const CURSOR_POS = 'CURSOR_POS'
const CURSOR_STYLE = 'CURSOR_STYLE'

// Action
export const toggleCursorPos = cursorPos => ({
  type: CURSOR_POS,
  cursorPos
})

export const toggleCursorStyle = cursorStyle => ({
  type: CURSOR_STYLE,
  cursorStyle
})

// Reducer 
export default (state = initialState, action) => {
  switch (action.type) {
    case CURSOR_POS:
      return {...state, cursorPos: action.cursorPos }
    case CURSOR_STYLE:
      return {...state, cursorStyle: action.cursorStyle }
    default:
      return state
  }
}