// Initial State
const initialState = {
  overlayColour: 'charcoal',
  overlayActive: false
}

const OVERLAY_COLOUR = 'OVERLAY_COLOUR';
const OVERLAY_ACTIVE = 'OVERLAY_ACTIVE';

// Action
export const toggleOverlayColour = overlayColour => ({
  type: OVERLAY_COLOUR,
  overlayColour
})

export const toggleOverlayActive = overlayActive => ({
  type: OVERLAY_ACTIVE,
  overlayActive
})

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case OVERLAY_COLOUR:
      return { ...state, overlayColour: action.overlayColour }
    case OVERLAY_ACTIVE:
      return { ...state, overlayActive: action.overlayActive }
    default:
      return state
  }
}