// Iniitial State
const initialState = {
  background: 'charcoal'
};

const BACKGROUND = 'BACKGROUND';

// Action
export const toggleBackground = background => ({
  type: BACKGROUND, 
  background
});

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case BACKGROUND:
      return { ...state, background: action.background };
    default:
      return state;
  }
};