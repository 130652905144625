// Initial State
const initialState = {
  breadcrumbs: false,
  breadcrumbsShow: true,
}

const BREADCRUMBS = "BREADCRUMBS"
const BREADCRUMBS_SHOW = "BREADCRUMBS_SHOW"

// Action
export const setBreadcrumbs = breadcrumbs => ({
  type: BREADCRUMBS,
  breadcrumbs,
})

export const toggleBreadcrumbsShow = breadcrumbsShow => ({
  type: BREADCRUMBS_SHOW,
  breadcrumbsShow,
})

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case BREADCRUMBS:
      return { ...state, breadcrumbs: action.breadcrumbs }
    case BREADCRUMBS_SHOW:
      return { ...state, breadcrumbsShow: action.breadcrumbsShow }
    default:
      return state
  }
}
