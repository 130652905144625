// Initial State
const initialState = {
  logoHasSymbol: true
}

// Set our constants
const LOGO_HAS_SYMBOL = 'LOGO_HAS_SYMBOL';

// Actions
export const toggleLogoHasSymbol = logoHasSymbol => ({
  type: LOGO_HAS_SYMBOL,
  logoHasSymbol
});

// Reducer 
export default (state = initialState, action) => {
  switch (action.type) {
    case LOGO_HAS_SYMBOL:
      return {...state, logoHasSymbol: action.logoHasSymbol };
    default:
      return state
  }
};