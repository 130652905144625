// Iniitial State
const initialState = {
  navActive: false,
};

// Action
const TOGGLE_NAV = 'TOGGLE_NAV';

export const toggleNavActive = navActive => ({
  type: TOGGLE_NAV, 
  navActive
});

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_NAV:
      return { ...state, navActive: action.navActive };
    default:
      return state;
  }
};