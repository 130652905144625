import { combineReducers } from "redux"
import navActive from "./navActive"
import background from "./background"
import overlayActive from "./overlay"
import overlayColour from "./overlay"
import siteHeaderActive from "./header"
import stickyHeader from "./header"
import stickyHeaderColour from "./header"
import logoHasSymbol from "./logo"
import breadcrumbs from "./breadcrumbs"
import breadcrumbsShow from "./breadcrumbs"
import initialSiteLoad from "./initialSiteLoad"
import siteLoaded from "./initialSiteLoad"
import cursorPos from "./cursor"
import cursorStyle from "./cursor"

export default combineReducers({
  navActive,
  background,
  overlayActive,
  overlayColour,
  siteHeaderActive,
  stickyHeader,
  stickyHeaderColour,
  logoHasSymbol,
  breadcrumbs,
  breadcrumbsShow,
  cursorPos,
  cursorStyle,
  initialSiteLoad,
  siteLoaded,
})
