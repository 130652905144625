// Initial State
const initialState = {
  initialSiteLoad: true,
  siteLoaded: false,
}

const INITIAL_SITE_LOAD = "INITIAL_SITE_LOAD"
const SITE_LOADED = "SITE_LOADED"

// Action
export const toggleInitialSiteLoad = initialSiteLoad => ({
  type: INITIAL_SITE_LOAD,
  initialSiteLoad,
})

export const toggleSiteLoaded = siteLoaded => ({
  type: SITE_LOADED,
  siteLoaded,
})

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_SITE_LOAD:
      return { ...state, initialSiteLoad: action.initialSiteLoad }
    case SITE_LOADED:
      return { ...state, siteLoaded: action.siteLoaded }
    default:
      return state
  }
}
