// Initial State
const initialState = {
  siteHeaderActive: true,
  stickyHeader: false,
  stickyHeaderColour: 'charcoal'
};

const STICKY_HEADER = 'STICKY_HEADER';
const STICKY_HEADER_COLOUR = 'STICKY_HEADER_COLOUR';
const SITE_HEADER = 'SITE_HEADER';

// Action
export const toggleStickyHeader = stickyHeader => ({
  type: STICKY_HEADER,
  stickyHeader
});

export const toggleStickyHeaderColour = stickyHeaderColour => ({
  type: STICKY_HEADER_COLOUR,
  stickyHeaderColour
});

export const toggleSiteHeader = siteHeaderActive => ({
  type: SITE_HEADER,
  siteHeaderActive
});

// Reducer 
export default (state = initialState, action) => {
  switch (action.type) {
    case STICKY_HEADER:
      return {...state, stickyHeader: action.stickyHeader };
    case STICKY_HEADER_COLOUR:
      return {...state, stickyHeaderColour: action.stickyHeaderColour };
    case SITE_HEADER:
      return {...state, siteHeaderActive: action.siteHeaderActive };
      default:
    return state
  }
};